import React from "react";
import { Box } from "@chakra-ui/react";

const HeroEllipse = () => {
  return (
    <Box
      w={"925px"}
      h={"925px"}
      zIndex={2}
      borderRadius={"100%"}
      style={{
        backgroundColor: "rgba(0, 178, 149, 0.2)",
        filter: "blur(1000px)",
        MozFilter: "blur(600px)",
        OFilter: "blur(600px)",
        MsFilter: "blur(600px)",
      }}
      left={"-147px"}
      top={"4px"}
      position={"absolute"}
    />
  );
};

export default HeroEllipse;
