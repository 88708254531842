
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    
import Page from "@/src/components/Page";
import LandingShell from "@/src/layouts/LandingShell";
import LandingHeader from "@/src/components/LandingHeader";
import Hero from "@/src/components/Hero";
import LandingFooter from "@/src/components/LandingFooter";
import React from "react";
import NewsLetter from "@/src/components/NewsLetter";
import Demo from "@/src/components/Demo";
import HeroEllipse from "@/src/components/HeroEllipse";
import FeaturesNew from "@/src/components/FeaturesNew";

function Home() {
  return (
	  <LandingShell>
		  <LandingHeader/>
		  <Hero/>
		  <FeaturesNew/>
		  {/*<Features/>*/}
		  <Demo/>
		  <NewsLetter/>
		  <LandingFooter/>
	  </LandingShell>
  )
}

const LandingPage = () => (
    <Page name="Landing" path="/">
      <Home/>
    </Page>
);

export default LandingPage;

    export async function getStaticProps(ctx) {
        
        
        return {
          
          
          props: {
            
            ...(await __loadNamespaces({
              ...ctx,
              pathname: '/index',
              loaderName: 'getStaticProps',
              ...__i18nConfig,
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
            }))
          }
        }
    }
  