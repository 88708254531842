import React from "react";
import { Box, Flex, Image, Stack, Text, useColorMode } from "@chakra-ui/react";

const AlertFeature = ({ src, title, text, boxColor, ...props }) => {
  const { colorMode } = useColorMode();
  return (
    <Stack
      bg={colorMode === "light" ? "white" : "transparent"}
      border={colorMode === "light" ? "initial" : "1px solid #00B295"}
      px={{ base: "28px", md: "48px", "2xl": "48px" }}
      py={{ base: "25px", md: "48px", "2xl": "35px" }}
      borderRadius={{ base: "20px", md: "30px" }}
      h={{ base: "auto", md: "379px", xl: "379px", "2xl": "379px" }}
      width={{ base: "auto", md: "308px", xl: "308px", "2xl": "308px" }}
      shadow={"md"}
      {...props}
    >
      <Flex
        align={"center"}
        direction={"column"}
        justify={"space-between"}
        textAlign={"center"}
      >
        <Box
          px={{ base: "28px", md: "28px", "2xl": "28px" }}
          py={{ base: "28px", md: "28px", "2xl": "28px" }}
          w={{ base: "100px", md: "112px", "2xl": "112px" }}
          h={{ base: "100px", md: "112px", "2xl": "112px" }}
          bg={colorMode === "light" ? boxColor : "rgba(0, 178, 149, 0.26)"}
          mb={4}
          borderRadius={"20px"}
        >
          <Image alt={title} src={src} />
        </Box>
        <Text
          color={colorMode === "light" ? "inherit" : "white"}
          fontSize={"md"}
          fontWeight={600}
          mb={4}
        >
          {title}
        </Text>
        <Text
          fontSize={{ base: "14px", xl: "18px", "2xl": "20px" }}
          noOfLines={6}
          color={colorMode === "light" ? "inherit" : "white"}
        >
          {text}
        </Text>
      </Flex>
    </Stack>
  );
};

export default AlertFeature;
