import React, {useEffect, useRef, useState} from 'react';
import {AspectRatio, Box, Flex, IconButton} from "@chakra-ui/react";
import {
	MdFastForward,
	MdFastRewind, MdOutlinePause,
	MdPlayArrow,
} from "react-icons/md";

const VideoPlayer = ({zIndex, ...props}) => {
	const videoRef = useRef(null)
	const [isMounted, setIsMounted] = useState(false);
	const [playing, setPlaying] = useState(false);
	const [videoTime, setVideoTime] = useState(0);
	const [currentTime, setCurrentTime] = useState(0);
	const [progress, setProgress] = useState(0);

	useEffect(() => {
		setIsMounted(true)
	}, [])

	if (isMounted){
		window.setInterval(function () {
			setCurrentTime(videoRef.current?.currentTime);
			setProgress((videoRef.current?.currentTime / videoTime) * 100);
			checkVideoState();
		}, 1000);
	}
	const checkVideoState = () => {
		if (videoRef?.current?.ended || videoRef?.current?.paused){
			setPlaying(false);
		}else {
			setPlaying(true);
		}
	}
	const videoHandler = (e) => {
		e.preventDefault();
		if (videoRef.current?.paused) {
			videoRef.current?.play();
			setPlaying(true);
			const vid = document.getElementById("video-player");
			setVideoTime(vid.duration);
		} else {
			videoRef.current?.pause();
			setPlaying(false);
		}
	};

	const fastForward = (e) => {
		videoRef.current.currentTime += 5;
	};

	const fastRewind = (e) => {
		videoRef.current.currentTime -= 5;
	};

	return (
		<Flex alignItems={"center"} zIndex={zIndex} justifyContent={"center"} position={"relative"} className={"video-container"}>
			<AspectRatio w={{base: "350px", sm: '476px', md:'550px'}} maxH={"382px"} ratio={16/9}>
				<video ref={videoRef} id={"video-player"} preload="metadata" autoPlay={props?.autoPlay} poster={props?.poster}>
					<source src={props?.source} type="video/mp4"></source>
				</video>
			</AspectRatio>
			<Flex className={"state"} position={"absolute"} w={"100%"} h={"100%"} justifyContent={"center"} align={"center"}>
				<IconButton
					display={playing? "none": "block"}
					isRound
					onClick={videoHandler}
					variant='unstyled'
					color={"#00b295"}
					bg={"white"}
					aria-label='play'
					boxSize='80px'
					fontSize={"80px"}
					icon={playing? <MdOutlinePause />:<MdPlayArrow />}
				/>
			</Flex>
			<Flex
				display={playing? "flex":"none"}
				_hover={{
					opacity: 1,
				}}
				px={4}
				justifyContent={"space-around"}
				transition={"opacity 0.4s"}
				opacity={0.2}
				className="controls"
				position={"absolute"}
				w={"full"}
				bottom={0}
			>
				<IconButton
					isRound
					onClick={videoHandler}
					variant='unstyled'
					color={"white"}
		            aria-label='play'
		            fontSize='20px'
		            icon={playing? <MdOutlinePause />:<MdPlayArrow />}
				/>
				<IconButton
					isRound
					onClick={fastRewind}
					variant='unstyled'
					color={"white"}
					aria-label='rewind'
					fontSize='20px'
					icon={<MdFastRewind />}
				/>
				<Box className="timeline">
					<Box className="bar" mx={2}>
						<Box className="inner" w={`${progress}%`} />
					</Box>
				</Box>
				<IconButton
					isRound
					onClick={fastForward}
					color={"white"}
					variant='unstyled'
					aria-label='forward'
					fontSize='20px'
					icon={<MdFastForward />}
				/>
			</Flex>
		</Flex>
	);
}

export default VideoPlayer;