import React from "react";
import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";
import {
  Box,
  Button,
  Grid,
  Heading,
  Image,
  Stack,
  Text,
  useColorMode,
} from "@chakra-ui/react";
import FeaturesGrid from "@/src/components/FeaturesGrid";
import AlertFeature from "@/src/components/AlertFeature";

const FeaturesNew = () => {
  const router = useRouter();
  const { t } = useTranslation();
  const { colorMode } = useColorMode();
  return (
    <Grid
      as={"section"}
      id={"features"}
      pl={{ base: "20px", "2xl": "120px", "5xl": "450px" }}
      templateRows={{ base: "1fr", "2xl": "repeat(1, 1fr)" }}
      justifyItems={"space-between"}
      py={24}
      w={"full"}
      templateColumns={{
        base: "1fr",
        "2xl": "472px auto",
        "3xl": "0.8fr 1.9fr",
        "5xl": "0.8fr 1.1fr",
      }}
    >
      <Box
        zIndex={1}
        position={"relative"}
        direction={"column"}
        gridArea={"1/1/2/2"}
        alignSelf={"center"}
        w={{ "2xl": "70%" }}
      >
        <Image
          alt={"help-blob"}
          src={colorMode === "light" ? "./help.svg" : "./help_dark.svg"}
          position={"absolute"}
          transform={"auto"}
          translateX={"-35px"}
          translateY={"-38px"}
        />
        <Stack
          justify={"flex-start"}
          spacing={"48px"}
          w={"full"}
          pl={{ base: 4, "2xl": 0 }}
          pb={{ base: 12, "2xl": 0 }}
        >
          <Heading
            lineHeight={"138%"}
            maxW={{ "2xl": "312px", "3xl": "550px", "5xl": "470px" }}
            fontSize={{ base: "36px", "5xl": "38px" }}
            fontWeight={500}
            color={colorMode === "light" ? "inherit" : "#00B295"}
          >
            {t("home:hero_feature_text")}
          </Heading>
          <Text
            lineHeight={{ "2xl": "29px", "5xl": "160%" }}
            maxW={{ "2xl": "389px", "3xl": "421px", "2xl": "505px" }}
            fontSize={{ base: "18px", "3xl": "18px", "5xl": "26px" }}
            fontWeight={400}
            mt={4}
            color={colorMode === "light" ? "inherit" : "white"}
          >
            {t("home:hero_feature_description")}
          </Text>
          <Button
            lineHeight={{ base: "30px", "5xl": "34px" }}
            fontSize={{ base: "20px", "5xl": "22px" }}
            letterSpacing={"0.065em"}
            fontWeight={400}
            maxW={"172px"}
            onClick={() => {
              router.push("/account/choose");
            }}
            _focus={{ outline: "none" }}
            size={"lg"}
            variant={"solid"}
            colorScheme="brand"
          >
            {t("home:hero_start_button")}
          </Button>
        </Stack>
      </Box>
      <Box
        display={"flex"}
        position={"relative"}
        alignSelf={{ base: "center", "2xl": "end" }}
        justifyContent={{ base: "center", "2xl": "end" }}
        gridArea={{ base: "2/1/2/1", "2xl": "1/2/2/3" }}
        w={"100%"}
        h={"619px"}
      >
        <Box
          w={{ base: "100%", "5xl": "100%" }}
          h={"100%"}
          right={0}
          bg={"rgba(0, 178, 149, 0.33)"}
          position={"absolute"}
          borderRadius={"150px 0px 0px 0px"}
        />
        <FeaturesGrid
          position={"absolute"}
          top={{ base: "0px", "2xl": "-180px" }}
          right={{ "2xl": "118px", "3xl": "112px", "5xl": "450px" }}
          zIndex={1}
          alignSelf={"center"}
        >
          <AlertFeature
            text={t("home:feature_alert_description")}
            boxColor={"rgba(241, 247, 255, 1)"}
            title={t("home:feature_alert_title")}
            src={
              colorMode === "light"
                ? "./images/eva_alert-triangle-outline.svg"
                : "./images/eva_alert-triangle-outline-dark.svg"
            }
            gridArea={"alert / alert / alert / alert"}
            alignItems={"center"}
            justify={"center"}
          />
          <AlertFeature
            text={t("home:feature_report_description")}
            boxColor={"rgba(241, 247, 255, 1)"}
            src={"./images/carbon_report.svg"}
            title={t("home:feature_report_title")}
            gridArea={"report / report / report / report"}
            alignItems={"center"}
            justify={"center"}
          />
          <AlertFeature
            text={t("home:feature_patch_description")}
            boxColor={"rgba(241, 247, 255, 1)"}
            title={t("home:feature_patch_title")}
            src={"./images/path-management.svg"}
            gridArea={"patch / patch / patch / patch"}
            alignItems={"center"}
            justify={"center"}
          />
          <AlertFeature
            text={t("home:feature_poc_description")}
            boxColor={"rgba(241, 247, 255, 1)"}
            src={"./images/bi_person-video.svg"}
            title={t("home:feature_poc_title")}
            gridArea={"poc / poc / poc / poc"}
            alignItems={"center"}
            justify={"center"}
          />
        </FeaturesGrid>
        <Image
          alt={"dot"}
          zIndex={0}
          position={{ base: "absolute" }}
          top={"562px"}
          right={{ "2xl": "593px", "5xl": "1003px" }}
          src={colorMode === "light" ? "./dotdot.svg" : "./dotdot-dark.svg"}
        />
      </Box>
    </Grid>
  );
};

export default FeaturesNew;
