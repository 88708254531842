import React from "react";
import {
  AspectRatio,
  Box,
  Button,
  Heading,
  Image,
  Stack,
  Text,
  useColorMode,
} from "@chakra-ui/react";
import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";
import HeroEllipse from "@/src/components/HeroEllipse";

const Hero = () => {
  const router = useRouter();
  const { t } = useTranslation();
  const { colorMode } = useColorMode();
  return (
    <Stack
      as={"section"}
      zIndex={1}
      isolation={"isolate"}
      bg={colorMode === "light" ? "transparent" : "#021b17"}
      pl={{ "2xl": "120px", "5xl": "450px" }}
      direction={{ base: "column-reverse", "2xl": "row" }}
      w={{ base: "100vw", "2xl": "full" }}
      justifyContent={"space-between"}
      align={{ "2xl": "center" }}
    >
      <HeroEllipse />
      <Stack
        flex={{ base: "1 1 60%", "3xl": "1 1 45%", "5xl": "1 1 50%" }}
        spacing={{ base: "24px", "5xl": "36px" }}
        px={3}
        my={4}
        position={"relative"}
        zIndex={2}
      >
        <Heading
          color={"brand.600"}
          maxW={{ base: "100%", "5xl": "530px" }}
          letterSpacing={{ base: "normal", sm: "normal" }}
          fontStyle={"normal"}
          fontSize={{ base: "44px", "2xl": "54px", xl: "64px", "5xl": "74px" }}
          fontWeight={600}
          lineHeight={"119%"}
        >
          {t("home:hero_title")}
        </Heading>
        <Text
          fontSize={{ base: "18px", "3xl": "18px", "5xl": "26px" }}
          maxW={{ base: "100%", "5xl": "785px" }}
          lineHeight={"160%"}
          fontStyle={"normal"}
          fontWeight={400}
          color={colorMode === "light" ? "inherit" : "white"}
        >
          {t("home:hero_text")}
        </Text>
        <Stack
          direction={"row"}
          maxW={{ base: "370px", "5xl": "380px" }}
          py={{ base: "30px" }}
          spacing={8}
          justifyContent={"space-between"}
          position={"relative"}
          zIndex={2}
        >
          <Button
            lineHeight={"30px"}
            fontSize={"20px"}
            letterSpacing={"0.065em"}
            fontWeight={400}
            onClick={() => {
              router.push("/account/choose");
            }}
            _focus={{ outline: "none" }}
            size={"lg"}
            variant={"solid"}
            colorScheme="brand"
            py={"1.6rem"}
          >
            {t("home:hero_start_button")}
          </Button>
          <Button
            lineHeight={"30px"}
            fontSize={"20px"}
            letterSpacing={"0.065em"}
            fontWeight={400}
            onClick={() => {
              router.push("/docs/learn-more");
            }}
            _focus={{ outline: "none" }}
            size={"lg"}
            variant={"outline2"}
            colorScheme="brand"
            py={"1.6rem"}
            bg={colorMode === "light" ? "transparent" : "#314542"}
          >
            {t("home:hero_learn_button")}
          </Button>
        </Stack>
      </Stack>
      <Box
        flex={{ base: "0 1 78%", "3xl": "0 1 55%", "5xl": "0 1 100%" }}
        overflow={"hidden"}
        alignItems={"flex-end"}
        justifyContent={"flex-end"}
      >
        <AspectRatio ratio={12 / 9}>
          <Image w={"full"} src={"./right.png"} alt={"hero"} />
        </AspectRatio>
      </Box>
    </Stack>
  );
};

export default Hero;
