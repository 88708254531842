import React from "react";
import {
  Button,
  Flex,
  Heading,
  Image,
  Text,
  useBreakpointValue,
  useColorMode,
} from "@chakra-ui/react";
import VideoPlayer from "@/src/components/VideoPlayer";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";

const Demo = () => {
  const router = useRouter();
  let cover = "/watchman.png";
  let video = "/watchman.mp4";
  const { t } = useTranslation();
  const { colorMode } = useColorMode();
  return (
    <Flex
      as={"section"}
      direction={{ base: "column-reverse", lg: "row" }}
      gap={{ base: "2rem", "2xl": "98px" }}
      w={"full"}
      justifyContent={"space-around"}
      alignItems={"center"}
      pr={{ base: 4, "2xl": "120px", "5xl": "450px" }}
      pl={{ base: 4, "2xl": "120px", "5xl": "450px" }}
      py={24}
      mt={8}
    >
      <Flex
        /* flex={"1 0 auto"} */
        /* pl={{ "2xl": "108px", "5xl": "138px" }} */
        position={"relative"}
      >
        <Image
          zIndex={0}
          alt={"demo-top"}
          src={"./demo-top.svg"}
          position={"absolute"}
          transform={"auto"}
          translateY={useBreakpointValue({
            base: "-20%",
            sm: "-35%",
            md: "-25%",
          })}
          translateX={useBreakpointValue({
            base: "120%",
            sm: "190%",
            md: "250%",
          })}
        />
        <VideoPlayer zIndex={1} source={video} poster={cover} />
        <Image
          zIndex={0}
          alt={"demo-bottom"}
          src={"./demo-bottom.svg"}
          position={"absolute"}
          transform={"auto"}
          translateY={useBreakpointValue({
            base: "88%",
            sm: "150%",
            md: "180%",
          })}
          translateX={useBreakpointValue({
            base: "-30%",
            sm: "-35%",
            md: "-30%",
          })}
        />
      </Flex>
      <Flex
        flex={"1 1 auto"}
        maxW={{ "2xl": "428px", "3xl": "628px", "5xl": "728px" }}
        position={"relative"}
        direction={"column"}
        justify={"center"}
        gap={5}
        /* mb={{ base: 24, "2xl": 0 }} */
        align={"flex-start"}
      >
        <Heading
          fontSize={{ base: "38px", "2xl": "25px", "5xl": "40px" }}
          lineHeight={{
            base: "49px",
            "2xl": "40px",
            xl: "40px",
            "5xl": "58px",
          }}
          fontWeight={500}
          color={colorMode === "light" ? "inherit" : "#00B295"}
        >
          {t("home:demo_title")}
        </Heading>
        <Text
          fontSize={{ base: "18px", "3xl": "18px", "5xl": "26px" }}
          fontWeight={400}
          lineHeight={{
            base: "27px",
            "2xl": "30px",
            "3xl": "160%",
            "5xl": "160%",
          }}
          color={colorMode === "light" ? "inherit" : "white"}
        >
          {t("home:demo_description")}
        </Text>
        <Button
          maxW={"177px"}
          lineHeight={"30px"}
          fontSize={"20px"}
          letterSpacing={"0.065em"}
          fontWeight={400}
          onClick={() => {
            router?.push("/docs/learn-more");
          }}
          _focus={{ outline: "none" }}
          size={"lg"}
          variant={"outline2"}
          colorScheme="brand"
          bg={colorMode === "light" ? "transparent" : "#314542"}
        >
          {t("home:hero_learn_button")}
        </Button>
      </Flex>
    </Flex>
  );
};

export default Demo;
