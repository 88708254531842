import React from 'react';
import {Grid} from "@chakra-ui/react";

const FeaturesGrid = ({children, templateRows, templateColumns, columnGap, rowGap, ...props}) => {
	return (
		<Grid
			templateRows={"auto"}
			align={"flex-start"}
			justifyContent={"flex-end"}
			templateColumns={{base: "repeat(2, minmax(50%, 50%))", lg:"repeat(2, 50%)"}}
			rowGap={"30px"}
			columnGap={"34px"}
			templateAreas={{base:`
				". report"
				"alert report"
				"alert report"
				"alert report"
				"alert poc"
				"patch poc"
				"patch poc"
				"patch poc"
				"patch ."
			`}}
			{...props}
		>
			{children}
		</Grid>
	);
};

export default FeaturesGrid;